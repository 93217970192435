import React from "react";
import App from "../../containers/App";
import Footer from "../../components/footer";
import {
  Card,
  CardGroup,
  Container,
  Row,
  Col,
  ListGroup,
} from "react-bootstrap";
import "../sobre-cursos.css";

const geral_cursos = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "10px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p className="sbr-titulo">CURSOS</p>
        </Col>
      </Row>
    </Container>

    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "100px",
      }}
    >
      <Row xs={1} md={11} className="g-4">
        <Col>
          <p className="sbr-list-titulo">Lista de Opções</p>
          <ListGroup variant="flush">
            <a className="sbr-links" href="curso_ads">
              <ListGroup.Item>
                Análise e Desenvolvimento de Sistemas
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/curso_bd">
              <ListGroup.Item variant="warning">Banco de Dados</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/curso_dsm">
              <ListGroup.Item>
                Desenvolvimento de Software Multiplataforma
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/curso_gpi">
              <ListGroup.Item variant="warning">
                Gestão da Produção Industrial
              </ListGroup.Item>
            </a>
			<a className="sbr-links" href="/curso_ge_ead">
              <ListGroup.Item>
                Gestão Empresarial (EaD)
              </ListGroup.Item>
            </a>
			<a className="sbr-links" href="/curso_log">
              <ListGroup.Item variant="warning">
                Logística
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/curso_mav">
              <ListGroup.Item>
			    Manufatura Avançada</ListGroup.Item>
            </a>
            <a className="sbr-links" href="/curso_mnt">
              <ListGroup.Item variant="warning">
                Manutenção de Aeronaves
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/curso_pea">
              <ListGroup.Item>
                Projetos de Estruturas Aeronáuticas
              </ListGroup.Item>
            </a>
            <a className="sbr-links" href="/sobre_cursos">
              <ListGroup.Item variant="warning">Sobre os cursos</ListGroup.Item>
            </a>
            <a
              className="sbr-links"
              href="https://vestibular.fatec.sp.gov.br/"
              target="_blank" rel="noopener noreferrer"
            >
              <ListGroup.Item>Vestibular</ListGroup.Item>
            </a>
          </ListGroup>
        </Col>
      </Row>
    </Container>

    <Footer />
  </App>
);

export default geral_cursos;
